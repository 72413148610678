<template>
  <div id="ppt">
    <div class="box">
      <div class="main">
        <!-- <img :src="imgurl" alt="" /> -->
        <iframe
          id="iframe1"
          width="1362"
          height="838"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="ppt"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      ppt: "",
      imgurl: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
        this.ppt =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          "http://zy.yunlearning.com" +
          res.data.file_path;
        this.imgurl = res.data.cover;
        console.log(res);
      });
    },
  },
};
</script>
<style lang="less" scoped>
#ppt {
  height: 100%;
  .box {
    height: 100%;
    // min-height: 1020px;
    position: relative;
  }
  .main {
    width: 91%;
    // height: 800px;
    // background-color: lightseagreen;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/deep/#ReadingToolbarPanel {
  display: none !important;
}
.cui-topBar2 {
  display: none !important;
}
</style>